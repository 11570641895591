import React from 'react';
import { Link } from 'react-router-dom';
import { Leagues } from '../../utils/cardsMappingData';
import '../../styles/mobile/sportsMobile.css';

const SportsView = () => {
  return (
    <div className="w-full p-4 flex flex-col items-center justify-center sports-container">
      <div className="text-indigo-950 text-xl font-semibold mb-2">
        SELECT A SPORT
      </div>

      <div className="flex flex-col justify-center items-center w-full">
        <div className="flex justify-center space-x-4 mb-8">
          {Leagues.slice(0, 4).map((sport, index) => (
            <Link
              to={
                sport.teamId
                  ? `/sports/team/${sport.teamId}`
                  : `/sports/gameSchedule/${sport.sport}?sport=${sport.sport}&league=${sport.league}`
              }
              key={index}
              className="flex flex-col items-center bg-white rounded-xl border border-zinc-700 text-center p-4 sport-card"
              style={{ minWidth: '10rem', maxWidth: '12rem' }}
            >
              <img
                className="w-auto h-24 object-cover"
                src={sport.imageUrl}
                alt={sport.title}
                loading="lazy"
              />
              <span className="mt-4 text-zinc-800 text-lg font-normal leading-tight">
                {sport.title}
              </span>
            </Link>
          ))}
        </div>
        <div className="flex justify-center space-x-4 mb-8">
          {Leagues.slice(4, 8).map((sport, index) => (
            <Link
              to={
                sport.teamId
                  ? `/sports/team/${sport.teamId}`
                  : `/sports/gameSchedule/${sport.sport}?sport=${sport.sport}&league=${sport.league}`
              }
              key={index}
              className="flex flex-col items-center bg-white rounded-xl border border-zinc-700 text-center p-4 sport-card"
              style={{ minWidth: '10rem', maxWidth: '10rem' }}
            >
              <img
                className="w-auto h-24 object-cover"
                src={sport.imageUrl}
                alt={sport.title}
                loading="lazy"
              />
              <span className="mt-4 text-zinc-800 text-lg font-normal leading-tight">
                {sport.title}
              </span>
            </Link>
          ))}
        </div>
        <div className="flex justify-center space-x-4 mb-8">
          {Leagues.slice(8, 12).map((sport, index) => (
            <Link
              to={
                sport.teamId
                  ? `/sports/team/${sport.teamId}`
                  : `/sports/gameSchedule/${sport.sport}?sport=${sport.sport}&league=${sport.league}`
              }
              key={index}
              className="flex flex-col items-center bg-white rounded-xl border border-zinc-700 text-center p-4 sport-card"
              style={{ minWidth: '10rem', maxWidth: '12rem' }}
            >
              <img
                className="w-auto h-24 object-cover"
                src={sport.imageUrl}
                alt={sport.title}
                loading="lazy"
              />
              <span className="mt-4 text-zinc-800 text-lg font-normal leading-tight">
                {sport.title}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SportsView;