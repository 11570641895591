// before val implementation
// import React, { useEffect, useMemo, useState } from 'react';
// import { getUpcomingMatchups } from '../../../redux/actions/SportsActions';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import { formatDateToMonthDay } from '../../../utils/dateUtils';
// import { Link } from 'react-router-dom';
// import '../../../styles/Dashboard/dashboardStyle.css';
// import { 
//   getMoneylineForTeam, 
//   getSpreadOrTotalGoalsForTeam, 
//   getTotalForTeam, 
//   getTotalRounds, 
//   getMethodOfVictory } from '../../../utils/sportStats/oddsFunctions';
// import { Loading } from '../../../components/LoadingComponent';
// import { formatTimeOrDate } from '../../../utils/dateUtils';

// export const OddsTable = ({ oddsData, sportsData, darkMode, league}) => {
//   const today = moment();
//   const yesterday = moment().subtract(1, 'days');
//   const tomorrow = moment().add(1, 'days');

//   const toValueFormat = date => moment(date).format('YYYY-MM-DD');

//   const dateOptions = [
//     { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
//     { value: toValueFormat(today), label: formatDateToMonthDay(today) },
//     { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
//   ];

//   const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
//   const dispatch = useDispatch();
  
//   const [detailedOddsData, setDetailedOddsData] = useState([]);

//   useEffect(() => {
//     const addTeamDetails = () => {
//       const detailedData = oddsData.map(game => {
//         const homeTeamDetails = sportsData.find(team => team.team_name === game.home_team);
//         const awayTeamDetails = sportsData.find(team => team.team_name === game.away_team);

//         return {
//           ...game,
//           home_team_details: homeTeamDetails,
//           away_team_details: awayTeamDetails
//         };
//       });
//       setDetailedOddsData(detailedData);
//     };

//     addTeamDetails();
//   }, [oddsData, sportsData]);

//   return detailedOddsData && detailedOddsData.length > 0 ? (
//     <>
//       <p className={`mb-3 ${darkMode ? 'text-white' : 'text-black'} `}>CLICK GAME FOR PLAYER PROPS</p>
//       <div className="w-full rounded-lg overflow-hidden">
//         {/* Header */}
//         <div className="grid grid-cols-10 bg-black text-white text-xs sm:text-sm lg:text-base font-semibold py-3">
//           <div className="col-span-2 text-center">Date/Time</div>
//           <div className="col-span-2">{detailedOddsData[0].sport === 'golf' ? 'Tournament' : 'Team'}</div>
//           <div className="col-span-2 text-center">{detailedOddsData[0].sport === 'golf' ? '' : 'Moneyline'}</div>
//           <div className="col-span-2 text-center">
//               { detailedOddsData[0].sport === 'golf' ? '' 
//               : detailedOddsData[0].sport === 'mma' ? 'Method Of Victory' 
//               : detailedOddsData[0].sport === 'esports' ? 'Map Handicap'
//               : detailedOddsData[0].sport === 'soccer' ? 'Total Goals'
//               : 'Spread'}
//           </div>
//           <div className="col-span-2 text-center">
//               {detailedOddsData[0].sport === 'golf' ? ''
//               : detailedOddsData[0].sport === 'mma' ? 'Total Rounds'
//               : detailedOddsData[0].sport === 'esports'? 'Total Maps'
//               : 'Total'}
//           </div>
//         </div>

//         {/* Data Rows */}
//         {detailedOddsData.map((game, index) => {
//           if (!game || !game.odds || !game.odds[0]) return null;

//           const homeMoneyline = getMoneylineForTeam(game.odds, game.home_team);
//           const awayMoneyline = getMoneylineForTeam(game.odds, game.away_team);

//           const matchingSpreads = getSpreadOrTotalGoalsForTeam(
//             game.odds,
//             game.home_team,
//             game.away_team,
//             game.sport,
//           );

//           const methodOfVictoryHome = getMethodOfVictory(game.odds, game.home_team);
//           const methodOfVictoryAway = getMethodOfVictory(game.odds, game.away_team);

//           const homeTotal = getTotalForTeam(game?.odds, 'under');
//           const awayTotal = getTotalForTeam(game?.odds, 'over');

//           const totalRoundsU = getTotalRounds(game?.odds, 'under');
//           const totalRoundsO = getTotalRounds(game?.odds, 'over');


//           const isMMA = game.sport === 'mma';
//           const isGolf = game.sport === 'golf';
//           const wrapperProps = isMMA
//             ? { to: `/sports/ufcMatchup/${game.id}` }
//             : isGolf
//               ? { to: `/sports/pgaOdds/${game.id}` }
//               : { to: `/sports/propBets/${game.id}` };;

//           return (
//             <Link key={index} {...wrapperProps} className="block bg-neutral-50 hover:bg-gray-200 m-2 rounded-lg shadow">
//               <div className="grid grid-cols-10 items-center py-3">
//                 {/* Date/Time */}
//                 <div className="col-span-2 text-center">
//                   <span className="flex justify-center items-center text-zinc-500 text-xs sm:text-sm font-normal">
//                     {formatTimeOrDate(game.start_date)}
//                   </span>
//                 </div>

//                 {/* Team Name with Logos */}
//                 <div className="col-span-2 flex flex-col items-start matchup-team-container">
//                   <div className="flex items-center gap-2">
//                     <img
//                       className="w-6 h-6 object-cover"
//                       src={game.away_team_details?.logo || 'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'}
//                       alt="Away Team Logo"
//                       loading="lazy"
//                     />
//                     <span className="text-indigo-950 text-xs sm:text-sm lg:text-base font-medium">
//                       {game.away_team}
//                     </span>
//                   </div>
//                   <div className="flex items-center gap-2">
//                     <img
//                       className="w-6 h-6 object-cover"
//                       src={game.home_team_details?.logo || 'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'}
//                       alt="Home Team Logo"
//                       loading="lazy"
//                     />
//                     <span className="text-indigo-950 text-xs sm:text-sm lg:text-base font-medium">
//                       {game.home_team}
//                     </span>
//                   </div>
//                 </div>

//                 {/* Moneyline */}
//                 <div className="col-span-1 lg:col-span-2 lg:ml-6 odds-column">
//                   <div className="flex justify-center items-center space-x-3 mb-3">
//                     <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                       {awayMoneyline?.price}
//                     </span>
//                   </div>
//                   <div className="flex justify-center items-center space-x-3">
//                     <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                       {homeMoneyline?.price}
//                     </span>
//                   </div>
//                 </div>

//                 {/* Spread */}
//                 {detailedOddsData[0].sport === 'mma' ? (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="flex justify-around items-center space-x-3 mb-3">
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryAway[0]?.name.split(' - ')[1]}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryAway[0]?.price}
//                       </span>
//                     </div>
//                     <div className="flex justify-around items-center space-x-3">
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryHome[0]?.name.split(' - ')[1]}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryHome[0]?.price}
//                       </span>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="flex justify-around items-center space-x-3 mb-3">
//                       <span className="spreadPoints text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.awayOdds?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.awayOdds?.price}
//                       </span>
//                     </div>
//                     <div className="flex justify-around items-center space-x-3">
//                       <span className="spreadPoints text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.homeOdds?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.homeOdds?.price}
//                       </span>
//                     </div>
//                   </div>
//                 )}

//                 {/* Total */}
//                 {detailedOddsData[0].sport === 'mma' ? (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="total-row mb-3 ">
//                       <span className="text-green-600 total-label">O </span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsO?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsO?.price}
//                       </span>
//                     </div>
//                     <div className="total-row">
//                       <span className="text-red-600 total-label">U </span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsU?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsU?.price}
//                       </span>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="total-row mb-3">
//                       <span className="text-green-600 total-label">O</span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {awayTotal?.selection_points}
//                       </span>
//                       <span className="total-price text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {awayTotal?.price}
//                       </span>
//                     </div>
//                     <div className="total-row">
//                       <span className="text-red-600 total-label">U</span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {homeTotal?.selection_points}
//                       </span>
//                       <span className="total-price text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {homeTotal?.price}
//                       </span>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </Link>
//           );
//         })}
//       </div>
//     </>
//   ) : (
//     <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
//       <Loading />
//     </div>
//   );
// };


// trying to fix the images for esports (val first)
// GOT ALL MATCHUPS FROM STORE LIKE TRENDING TICKER

// import React, { useEffect, useMemo, useState } from 'react';
// import { getUpcomingMatchups } from '../../../redux/actions/SportsActions';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import { formatDateToMonthDay } from '../../../utils/dateUtils';
// import { Link } from 'react-router-dom';
// import '../../../styles/Dashboard/dashboardStyle.css';
// import { 
//   getMoneylineForTeam, 
//   getSpreadOrTotalGoalsForTeam, 
//   getTotalForTeam, 
//   getTotalRounds, 
//   getMethodOfVictory } from '../../../utils/sportStats/oddsFunctions';
// import { Loading } from '../../../components/LoadingComponent';
// import { formatTimeOrDate } from '../../../utils/dateUtils';

// export const OddsTable = ({ oddsData, sportsData, darkMode, league}) => {
//   const [selectedLeague, setSelectedLeague] = useState(league);
//   const [selectedDate, setSelectedDate] = useState(toValueFormat(today));
  
//   const today = moment();
//   const yesterday = moment().subtract(1, 'days');
//   const tomorrow = moment().add(1, 'days');

//   const toValueFormat = date => moment(date).format('YYYY-MM-DD');

//   const dateOptions = [
//     { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
//     { value: toValueFormat(today), label: formatDateToMonthDay(today) },
//     { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
//   ];

//   const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
//   const dispatch = useDispatch();
//   console.log("matchups from store", matchupsFromStore);

//   useEffect(() => {
//       dispatch(getUpcomingMatchups(league.sport, league.league));
//   }, [dispatch, selectedDate]);

//   useEffect(() => {
//     if (selectedSport) {
//       setSelectedLeague(selectedSport);
//     }
//   }, [selectedSport]);

  
//   const [detailedOddsData, setDetailedOddsData] = useState([]);

//   useEffect(() => {
//     const addTeamDetails = () => {
//       const detailedData = oddsData.map(game => {
//         const homeTeamDetails = sportsData.find(team => team.team_name === game.home_team);
//         const awayTeamDetails = sportsData.find(team => team.team_name === game.away_team);

//         return {
//           ...game,
//           home_team_details: homeTeamDetails,
//           away_team_details: awayTeamDetails
//         };
//       });
//       setDetailedOddsData(detailedData);
//     };

//     addTeamDetails();
//   }, [oddsData, sportsData]);

//   return detailedOddsData && detailedOddsData.length > 0 ? (
//     <>
//       <p className={`mb-3 ${darkMode ? 'text-white' : 'text-black'} `}>CLICK GAME FOR PLAYER PROPS</p>
//       <div className="w-full rounded-lg overflow-hidden">
//         {/* Header */}
//         <div className="grid grid-cols-10 bg-black text-white text-xs sm:text-sm lg:text-base font-semibold py-3">
//           <div className="col-span-2 text-center">Date/Time</div>
//           <div className="col-span-2">{detailedOddsData[0].sport === 'golf' ? 'Tournament' : 'Team'}</div>
//           <div className="col-span-2 text-center">{detailedOddsData[0].sport === 'golf' ? '' : 'Moneyline'}</div>
//           <div className="col-span-2 text-center">
//               { detailedOddsData[0].sport === 'golf' ? '' 
//               : detailedOddsData[0].sport === 'mma' ? 'Method Of Victory' 
//               : detailedOddsData[0].sport === 'esports' ? 'Map Handicap'
//               : detailedOddsData[0].sport === 'soccer' ? 'Total Goals'
//               : 'Spread'}
//           </div>
//           <div className="col-span-2 text-center">
//               {detailedOddsData[0].sport === 'golf' ? ''
//               : detailedOddsData[0].sport === 'mma' ? 'Total Rounds'
//               : detailedOddsData[0].sport === 'esports'? 'Total Maps'
//               : 'Total'}
//           </div>
//         </div>

//         {/* Data Rows */}
//         {detailedOddsData.map((game, index) => {
//           if (!game || !game.odds || !game.odds[0]) return null;

//           const homeMoneyline = getMoneylineForTeam(game.odds, game.home_team);
//           const awayMoneyline = getMoneylineForTeam(game.odds, game.away_team);

//           const matchingSpreads = getSpreadOrTotalGoalsForTeam(
//             game.odds,
//             game.home_team,
//             game.away_team,
//             game.sport,
//           );

//           const methodOfVictoryHome = getMethodOfVictory(game.odds, game.home_team);
//           const methodOfVictoryAway = getMethodOfVictory(game.odds, game.away_team);

//           const homeTotal = getTotalForTeam(game?.odds, 'under');
//           const awayTotal = getTotalForTeam(game?.odds, 'over');

//           const totalRoundsU = getTotalRounds(game?.odds, 'under');
//           const totalRoundsO = getTotalRounds(game?.odds, 'over');


//           const isMMA = game.sport === 'mma';
//           const isGolf = game.sport === 'golf';
//           const wrapperProps = isMMA
//             ? { to: `/sports/ufcMatchup/${game.id}` }
//             : isGolf
//               ? { to: `/sports/pgaOdds/${game.id}` }
//               : { to: `/sports/propBets/${game.id}` };;

//           return (
//             <Link key={index} {...wrapperProps} className="block bg-neutral-50 hover:bg-gray-200 m-2 rounded-lg shadow">
//               <div className="grid grid-cols-10 items-center py-3">
//                 {/* Date/Time */}
//                 <div className="col-span-2 text-center">
//                   <span className="flex justify-center items-center text-zinc-500 text-xs sm:text-sm font-normal">
//                     {formatTimeOrDate(game.start_date)}
//                   </span>
//                 </div>

//                 {/* Team Name with Logos */}
//                 <div className="col-span-2 flex flex-col items-start matchup-team-container">
//                   <div className="flex items-center gap-2">
//                     <img
//                       className="w-6 h-6 object-cover"
//                       src={game.away_team_details?.logo || 'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'}
//                       alt="Away Team Logo"
//                       loading="lazy"
//                     />
//                     <span className="text-indigo-950 text-xs sm:text-sm lg:text-base font-medium">
//                       {game.away_team}
//                     </span>
//                   </div>
//                   <div className="flex items-center gap-2">
//                     <img
//                       className="w-6 h-6 object-cover"
//                       src={game.home_team_details?.logo || 'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'}
//                       alt="Home Team Logo"
//                       loading="lazy"
//                     />
//                     <span className="text-indigo-950 text-xs sm:text-sm lg:text-base font-medium">
//                       {game.home_team}
//                     </span>
//                   </div>
//                 </div>

//                 {/* Moneyline */}
//                 <div className="col-span-1 lg:col-span-2 lg:ml-6 odds-column">
//                   <div className="flex justify-center items-center space-x-3 mb-3">
//                     <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                       {awayMoneyline?.price}
//                     </span>
//                   </div>
//                   <div className="flex justify-center items-center space-x-3">
//                     <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                       {homeMoneyline?.price}
//                     </span>
//                   </div>
//                 </div>

//                 {/* Spread */}
//                 {detailedOddsData[0].sport === 'mma' ? (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="flex justify-around items-center space-x-3 mb-3">
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryAway[0]?.name.split(' - ')[1]}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryAway[0]?.price}
//                       </span>
//                     </div>
//                     <div className="flex justify-around items-center space-x-3">
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryHome[0]?.name.split(' - ')[1]}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryHome[0]?.price}
//                       </span>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="flex justify-around items-center space-x-3 mb-3">
//                       <span className="spreadPoints text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.awayOdds?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.awayOdds?.price}
//                       </span>
//                     </div>
//                     <div className="flex justify-around items-center space-x-3">
//                       <span className="spreadPoints text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.homeOdds?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.homeOdds?.price}
//                       </span>
//                     </div>
//                   </div>
//                 )}

//                 {/* Total */}
//                 {detailedOddsData[0].sport === 'mma' ? (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="total-row mb-3 ">
//                       <span className="text-green-600 total-label">O </span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsO?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsO?.price}
//                       </span>
//                     </div>
//                     <div className="total-row">
//                       <span className="text-red-600 total-label">U </span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsU?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsU?.price}
//                       </span>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="total-row mb-3">
//                       <span className="text-green-600 total-label">O</span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {awayTotal?.selection_points}
//                       </span>
//                       <span className="total-price text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {awayTotal?.price}
//                       </span>
//                     </div>
//                     <div className="total-row">
//                       <span className="text-red-600 total-label">U</span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {homeTotal?.selection_points}
//                       </span>
//                       <span className="total-price text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {homeTotal?.price}
//                       </span>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </Link>
//           );
//         })}
//       </div>
//     </>
//   ) : (
//     <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
//       <Loading />
//     </div>
//   );
// };







// looks like we filter by league correctly and have access to team logos but no data rows appear after header
// import React, { useEffect, useMemo, useState } from 'react';
// import { getUpcomingMatchups } from '../../../redux/actions/SportsActions';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import { formatDateToMonthDay } from '../../../utils/dateUtils';
// import { Link } from 'react-router-dom';
// import '../../../styles/Dashboard/dashboardStyle.css';
// import { 
//   getMoneylineForTeam, 
//   getSpreadOrTotalGoalsForTeam, 
//   getTotalForTeam, 
//   getTotalRounds, 
//   getMethodOfVictory } from '../../../utils/sportStats/oddsFunctions';
// import { Loading } from '../../../components/LoadingComponent';
// import { formatTimeOrDate } from '../../../utils/dateUtils';

// export const OddsTable = ({ oddsData, sportsData, darkMode, league }) => {
//   const today = moment();
//   const yesterday = moment().subtract(1, 'days');
//   const tomorrow = moment().add(1, 'days');

//   const sport = sportsData[0].sport;

//   const toValueFormat = date => moment(date).format('YYYY-MM-DD');

//   const dateOptions = [
//     { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
//     { value: toValueFormat(today), label: formatDateToMonthDay(today) },
//     { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
//   ];

//   const [selectedDate, setSelectedDate] = useState(toValueFormat(today));
//   const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getUpcomingMatchups(sport, league));
//   }, [dispatch, league]);

//   // Filter matchups based on the selected league (commented out is filtering by date, for odds table i don't think it's that important)
//   const filteredMatchups = useMemo(() => {
//     const matchupsForLeague = matchupsFromStore[league] || [];
//     console.log("matchups for league: ", matchupsForLeague);
//     return matchupsForLeague;
//     // .filter(matchup => {
//     //   const gameDate = moment.utc(matchup.start_date).local().format('YYYY-MM-DD');
//     //   return gameDate === selectedDate;
//     }//);}
//     , [matchupsFromStore, league]);//, selectedDate]);

//   console.log("filtered matchups: ", filteredMatchups);
//   // console.log("leagues league: ", matchupsFromStore[league]);
//   // console.log("matchups for league: ", matchupsForLeague);


//   const [detailedOddsData, setDetailedOddsData] = useState([]);

//   useEffect(() => {
//     const addTeamDetails = () => {
//       const detailedData = filteredMatchups.map(game => {
//         const homeTeamDetails = sportsData.find(team => team.team_name === game.home_team);
//         const awayTeamDetails = sportsData.find(team => team.team_name === game.away_team);

//         return {
//           ...game,
//           home_team_details: homeTeamDetails,
//           away_team_details: awayTeamDetails
//         };
//       });
//       setDetailedOddsData(detailedData);
//     };

//     addTeamDetails();
//   }, [filteredMatchups, sportsData]);

//   return detailedOddsData && detailedOddsData.length > 0 ? (
//     <>
//       <p className={`mb-3 ${darkMode ? 'text-white' : 'text-black'} `}>CLICK GAME FOR PLAYER PROPS</p>
//       <div className="w-full rounded-lg overflow-hidden">
//         {/* Header */}
//         <div className="grid grid-cols-10 bg-black text-white text-xs sm:text-sm lg:text-base font-semibold py-3">
//           <div className="col-span-2 text-center">Date/Time</div>
//           <div className="col-span-2">{detailedOddsData[0].sport === 'golf' ? 'Tournament' : 'Team'}</div>
//           <div className="col-span-2 text-center">{detailedOddsData[0].sport === 'golf' ? '' : 'Moneyline'}</div>
//           <div className="col-span-2 text-center">
//               { detailedOddsData[0].sport === 'golf' ? '' 
//               : detailedOddsData[0].sport === 'mma' ? 'Method Of Victory' 
//               : detailedOddsData[0].sport === 'esports' ? 'Map Handicap'
//               : detailedOddsData[0].sport === 'soccer' ? 'Total Goals'
//               : 'Spread'}
//           </div>
//           <div className="col-span-2 text-center">
//               {detailedOddsData[0].sport === 'golf' ? ''
//               : detailedOddsData[0].sport === 'mma' ? 'Total Rounds'
//               : detailedOddsData[0].sport === 'esports'? 'Total Maps'
//               : 'Total'}
//           </div>
//         </div>

//         {/* Data Rows */}
//         {detailedOddsData.map((game, index) => {
//           if (!game || !game.odds || !game.odds[0]) return null;

          

//           const homeMoneyline = getMoneylineForTeam(game.odds, game.home_team);
//           const awayMoneyline = getMoneylineForTeam(game.odds, game.away_team);

//           const matchingSpreads = getSpreadOrTotalGoalsForTeam(
//             game.odds,
//             game.home_team,
//             game.away_team,
//             game.sport,
//           );

//           const methodOfVictoryHome = getMethodOfVictory(game.odds, game.home_team);
//           const methodOfVictoryAway = getMethodOfVictory(game.odds, game.away_team);

//           const homeTotal = getTotalForTeam(game?.odds, 'under');
//           const awayTotal = getTotalForTeam(game?.odds, 'over');

//           const totalRoundsU = getTotalRounds(game?.odds, 'under');
//           const totalRoundsO = getTotalRounds(game?.odds, 'over');

//           const isMMA = game.sport === 'mma';
//           const isGolf = game.sport === 'golf';
//           const wrapperProps = isMMA
//             ? { to: `/sports/ufcMatchup/${game.id}` }
//             : isGolf
//               ? { to: `/sports/pgaOdds/${game.id}` }
//               : { to: `/sports/propBets/${game.id}` };

//           return (
//             <Link key={index} {...wrapperProps} className="block bg-neutral-50 hover:bg-gray-200 m-2 rounded-lg shadow">
//               <div className="grid grid-cols-10 items-center py-3">
//                 {/* Date/Time */}
//                 <div className="col-span-2 text-center">
//                   <span className="flex justify-center items-center text-zinc-500 text-xs sm:text-sm font-normal">
//                     {formatTimeOrDate(game.start_date)}
//                   </span>
//                 </div>

//                 {/* Team Name with Logos */}
//                 <div className="col-span-2 flex flex-col items-start matchup-team-container">
//                   <div className="flex items-center gap-2">
//                     <img
//                       className="w-6 h-6 object-cover"
//                       src={game.away_team_details?.logo || 'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'}
//                       alt="Away Team Logo"
//                       loading="lazy"
//                     />
//                     <span className="text-indigo-950 text-xs sm:text-sm lg:text-base font-medium">
//                       {game.away_team}
//                     </span>
//                   </div>
//                   <div className="flex items-center gap-2">
//                     <img
//                       className="w-6 h-6 object-cover"
//                       src={game.home_team_details?.logo || 'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'}
//                       alt="Home Team Logo"
//                       loading="lazy"
//                     />
//                     <span className="text-indigo-950 text-xs sm:text-sm lg:text-base font-medium">
//                       {game.home_team}
//                     </span>
//                   </div>
//                 </div>

//                 {/* Moneyline */}
//                 <div className="col-span-1 lg:col-span-2 lg:ml-6 odds-column">
//                   <div className="flex justify-center items-center space-x-3 mb-3">
//                     <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                       {awayMoneyline?.price}
//                     </span>
//                   </div>
//                   <div className="flex justify-center items-center space-x-3">
//                     <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                       {homeMoneyline?.price}
//                     </span>
//                   </div>
//                 </div>

//                 {/* Spread */}
//                 {detailedOddsData[0].sport === 'mma' ? (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="flex justify-around items-center space-x-3 mb-3">
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryAway[0]?.name.split(' - ')[1]}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryAway[0]?.price}
//                       </span>
//                     </div>
//                     <div className="flex justify-around items-center space-x-3">
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryHome[0]?.name.split(' - ')[1]}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryHome[0]?.price}
//                       </span>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="flex justify-around items-center space-x-3 mb-3">
//                       <span className="spreadPoints text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.awayOdds?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.awayOdds?.price}
//                       </span>
//                     </div>
//                     <div className="flex justify-around items-center space-x-3">
//                       <span className="spreadPoints text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.homeOdds?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.homeOdds?.price}
//                       </span>
//                     </div>
//                   </div>
//                 )}

//                 {/* Total */}
//                 {detailedOddsData[0].sport === 'mma' ? (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="total-row mb-3 ">
//                       <span className="text-green-600 total-label">O </span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsO?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsO?.price}
//                       </span>
//                     </div>
//                     <div className="total-row">
//                       <span className="text-red-600 total-label">U </span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsU?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsU?.price}
//                       </span>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="total-row mb-3">
//                       <span className="text-green-600 total-label">O</span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {awayTotal?.selection_points}
//                       </span>
//                       <span className="total-price text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {awayTotal?.price}
//                       </span>
//                     </div>
//                     <div className="total-row">
//                       <span className="text-red-600 total-label">U</span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {homeTotal?.selection_points}
//                       </span>
//                       <span className="total-price text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {homeTotal?.price}
//                       </span>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </Link>
//           );
//         })}
//       </div>
//     </>
//   ) : (
//     <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
//       <Loading />
//     </div>
//   );
// };






// import React, { useEffect, useMemo, useState } from 'react';
// import { getUpcomingMatchups } from '../../../redux/actions/SportsActions';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import { formatDateToMonthDay } from '../../../utils/dateUtils';
// import { Link } from 'react-router-dom';
// import '../../../styles/Dashboard/dashboardStyle.css';
// import { 
//   getMoneylineForTeam, 
//   getSpreadOrTotalGoalsForTeam, 
//   getTotalForTeam, 
//   getTotalRounds, 
//   getMethodOfVictory 
// } from '../../../utils/sportStats/oddsFunctions';
// import { Loading } from '../../../components/LoadingComponent';
// import { formatTimeOrDate } from '../../../utils/dateUtils';

// export const OddsTable = ({ oddsData, sportsData, darkMode, league }) => {
//   const today = moment();
//   const yesterday = moment().subtract(1, 'days');
//   const tomorrow = moment().add(1, 'days');

//   const sport = sportsData[0]?.sport;

//   console.log("sport:", sport);

//   const toValueFormat = date => moment(date).format('YYYY-MM-DD');

//   const dateOptions = [
//     { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
//     { value: toValueFormat(today), label: formatDateToMonthDay(today) },
//     { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
//   ];

//   const [selectedDate, setSelectedDate] = useState(toValueFormat(today));
//   const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getUpcomingMatchups(sport, league));
//   }, [dispatch, league, sport]);

//   // Filter matchups based on the selected league
//   const filteredMatchups = useMemo(() => {
//     const matchupsForLeague = matchupsFromStore[league] || [];
//     console.log("matchups for league:", matchupsForLeague);
//     return matchupsForLeague;
//   }, [matchupsFromStore, league]);

//   console.log("filtered matchups:", filteredMatchups);

//   const detailedOddsData = filteredMatchups;

//   return detailedOddsData && detailedOddsData.length > 0 ? (
//     <>
//       <p className={`mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>CLICK GAME FOR PLAYER PROPS</p>
//       <div className="w-full rounded-lg overflow-hidden">
//         {/* Header */}
//         <div className="grid grid-cols-10 bg-black text-white text-xs sm:text-sm lg:text-base font-semibold py-3">
//           <div className="col-span-2 text-center">Date/Time</div>
//           <div className="col-span-2">{detailedOddsData[0].sport === 'golf' ? 'Tournament' : 'Team'}</div>
//           <div className="col-span-2 text-center">{detailedOddsData[0].sport === 'golf' ? '' : 'Moneyline'}</div>
//           <div className="col-span-2 text-center">
//             {detailedOddsData[0].sport === 'golf' ? '' 
//             : detailedOddsData[0].sport === 'mma' ? 'Method Of Victory' 
//             : detailedOddsData[0].sport === 'esports' ? 'Map Handicap'
//             : detailedOddsData[0].sport === 'soccer' ? 'Total Goals'
//             : 'Spread'}
//           </div>
//           <div className="col-span-2 text-center">
//             {detailedOddsData[0].sport === 'golf' ? ''
//             : detailedOddsData[0].sport === 'mma' ? 'Total Rounds'
//             : detailedOddsData[0].sport === 'esports'? 'Total Maps'
//             : 'Total'}
//           </div>
//         </div>

//         {/* Data Rows */}
//         {detailedOddsData.map((game, index) => {
//           if (!game || !Array.isArray(game.odds) || game.odds.length === 0) {
//             console.log(`Skipping game at index ${index} due to missing odds`, game);
//             return null;
//           }

//           const homeMoneyline = getMoneylineForTeam(game.odds, game.home_team);
//           const awayMoneyline = getMoneylineForTeam(game.odds, game.away_team);

//           const matchingSpreads = getSpreadOrTotalGoalsForTeam(
//             game.odds,
//             game.home_team,
//             game.away_team,
//             game.sport,
//           );

//           const methodOfVictoryHome = getMethodOfVictory(game.odds, game.home_team);
//           const methodOfVictoryAway = getMethodOfVictory(game.odds, game.away_team);

//           const homeTotal = getTotalForTeam(game?.odds, 'under');
//           const awayTotal = getTotalForTeam(game?.odds, 'over');

//           const totalRoundsU = getTotalRounds(game?.odds, 'under');
//           const totalRoundsO = getTotalRounds(game?.odds, 'over');

//           const isMMA = game.sport === 'mma';
//           const isGolf = game.sport === 'golf';
//           const wrapperProps = isMMA
//             ? { to: `/sports/ufcMatchup/${game.id}` }
//             : isGolf
//               ? { to: `/sports/pgaOdds/${game.id}` }
//               : { to: `/sports/propBets/${game.id}` };

//           return (
//             <Link key={index} {...wrapperProps} className="block bg-neutral-50 hover:bg-gray-200 m-2 rounded-lg shadow">
//               <div className="grid grid-cols-10 items-center py-3">
//                 {/* Date/Time */}
//                 <div className="col-span-2 text-center">
//                   <span className="flex justify-center items-center text-zinc-500 text-xs sm:text-sm font-normal">
//                     {formatTimeOrDate(game.start_date)}
//                   </span>
//                 </div>

//                 {/* Team Name with Logos */}
//                 <div className="col-span-2 flex flex-col items-start matchup-team-container">
//                   <div className="flex items-center gap-2">
//                     <img
//                       className="w-6 h-6 object-cover"
//                       src={game.away_team_details?.logo || 'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'}
//                       alt="Away Team Logo"
//                       loading="lazy"
//                     />
//                     <span className="text-indigo-950 text-xs sm:text-sm lg:text-base font-medium">
//                       {game.away_team}
//                     </span>
//                   </div>
//                   <div className="flex items-center gap-2">
//                     <img
//                       className="w-6 h-6 object-cover"
//                       src={game.home_team_details?.logo || 'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'}
//                       alt="Home Team Logo"
//                       loading="lazy"
//                     />
//                     <span className="text-indigo-950 text-xs sm:text-sm lg:text-base font-medium">
//                       {game.home_team}
//                     </span>
//                   </div>
//                 </div>

//                 {/* Moneyline */}
//                 <div className="col-span-1 lg:col-span-2 lg:ml-6 odds-column">
//                   <div className="flex justify-center items-center space-x-3 mb-3">
//                     <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                       {awayMoneyline?.price}
//                     </span>
//                   </div>
//                   <div className="flex justify-center items-center space-x-3">
//                     <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                       {homeMoneyline?.price}
//                     </span>
//                   </div>
//                 </div>

//                 {/* Spread */}
//                 {detailedOddsData[0].sport === 'mma' ? (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="flex justify-around items-center space-x-3 mb-3">
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryAway[0]?.name.split(' - ')[1]}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryAway[0]?.price}
//                       </span>
//                     </div>
//                     <div className="flex justify-around items-center space-x-3">
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryHome[0]?.name.split(' - ')[1]}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {methodOfVictoryHome[0]?.price}
//                       </span>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="flex justify-around items-center space-x-3 mb-3">
//                       <span className="spreadPoints text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.awayOdds?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.awayOdds?.price}
//                       </span>
//                     </div>
//                     <div className="flex justify-around items-center space-x-3">
//                       <span className="spreadPoints text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.homeOdds?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {matchingSpreads?.homeOdds?.price}
//                       </span>
//                     </div>
//                   </div>
//                 )}

//                 {/* Total */}
//                 {detailedOddsData[0].sport === 'mma' ? (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="total-row mb-3 ">
//                       <span className="text-green-600 total-label">O </span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsO?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsO?.price}
//                       </span>
//                     </div>
//                     <div className="total-row">
//                       <span className="text-red-600 total-label">U </span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsU?.selection_points}
//                       </span>
//                       <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {totalRoundsU?.price}
//                       </span>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="col-span-2 lg:ml-6 odds-column">
//                     <div className="total-row mb-3">
//                       <span className="text-green-600 total-label">O</span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {awayTotal?.selection_points}
//                       </span>
//                       <span className="total-price text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {awayTotal?.price}
//                       </span>
//                     </div>
//                     <div className="total-row">
//                       <span className="text-red-600 total-label">U</span>
//                       <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
//                         {homeTotal?.selection_points}
//                       </span>
//                       <span className="total-price text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
//                         {homeTotal?.price}
//                       </span>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </Link>
//           );
//         })}
//       </div>
//     </>
//   ) : (
//     <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
//       <Loading />
//     </div>
//   );
// };







import React, { useEffect, useMemo, useState } from 'react';
import { getUpcomingMatchups } from '../../../redux/actions/SportsActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { formatDateToMonthDay } from '../../../utils/dateUtils';
import { Link } from 'react-router-dom';
import '../../../styles/Dashboard/dashboardStyle.css';
import { 
  getMoneylineForTeam, 
  getSpreadOrTotalGoalsForTeam, 
  getTotalForTeam, 
  getTotalRounds, 
  getMethodOfVictory 
} from '../../../utils/sportStats/oddsFunctions';
import { Loading } from '../../../components/LoadingComponent';
import { formatTimeOrDate } from '../../../utils/dateUtils';

export const OddsTable = ({ oddsData, sportsData, darkMode, league }) => {
  const today = moment();
  const yesterday = moment().subtract(1, 'days');
  const tomorrow = moment().add(1, 'days');

  const sport = sportsData[0]?.sport;

  const toValueFormat = date => moment(date).format('YYYY-MM-DD');

  const dateOptions = [
    { value: toValueFormat(yesterday), label: formatDateToMonthDay(yesterday) },
    { value: toValueFormat(today), label: formatDateToMonthDay(today) },
    { value: toValueFormat(tomorrow), label: formatDateToMonthDay(tomorrow) },
  ];

  const [selectedDate, setSelectedDate] = useState(toValueFormat(today));
  const matchupsFromStore = useSelector(state => state.getUpcomingMatchups.matchups);
  const dispatch = useDispatch();
  
  const [detailedOddsData, setDetailedOddsData] = useState([]);

  useEffect(() => {
    if (sport) {
      dispatch(getUpcomingMatchups(sport, league));
    }
  }, [dispatch, league, sport]);

  // Filter matchups based on the selected league
  const filteredMatchups = useMemo(() => {
    const matchupsForLeague = matchupsFromStore[league] || [];
    return matchupsForLeague;
  }, [matchupsFromStore, league]);

  useEffect(() => {
    const addTeamDetails = () => {
      const detailedData = oddsData.map(game => {
        const homeTeamDetails = filteredMatchups.find(matchup => matchup.home_team === game.home_team)?.home_team_details;
        const awayTeamDetails = filteredMatchups.find(matchup => matchup.away_team === game.away_team)?.away_team_details;

        // const homeTeamDetails = (() ? filteredMatchups.home_team_details : []);
        // const awayTeamDetails = sportsData.find(team => team.team_name === game.away_team);
        return {
          ...game,
          home_team_details: homeTeamDetails,
          away_team_details: awayTeamDetails
        };
      });
      setDetailedOddsData(detailedData);
    };

    addTeamDetails();
  }, [oddsData, sportsData]);

  return detailedOddsData && detailedOddsData.length > 0 ? (
    <>
      <p className={`mb-3 ${darkMode ? 'text-white' : 'text-black'} `}>CLICK GAME FOR PLAYER PROPS</p>
      <div className="w-full rounded-lg overflow-hidden">
        {/* Header */}
        <div className="grid grid-cols-10 bg-black text-white text-xs sm:text-sm lg:text-base font-semibold py-3">
          <div className="col-span-2 text-center">Date/Time</div>
          <div className="col-span-2">{detailedOddsData[0].sport === 'golf' ? 'Tournament' : 'Team'}</div>
          <div className="col-span-2 text-center">{detailedOddsData[0].sport === 'golf' ? '' : 'Moneyline'}</div>
          <div className="col-span-2 text-center">
              { detailedOddsData[0].sport === 'golf' ? '' 
              : detailedOddsData[0].sport === 'mma' ? 'Method Of Victory' 
              : detailedOddsData[0].sport === 'esports' ? 'Map Handicap'
              : detailedOddsData[0].sport === 'soccer' ? 'Total Goals'
              : 'Spread'}
          </div>
          <div className="col-span-2 text-center">
              {detailedOddsData[0].sport === 'golf' ? ''
              : detailedOddsData[0].sport === 'mma' ? 'Total Rounds'
              : detailedOddsData[0].sport === 'esports'? 'Total Maps'
              : 'Total'}
          </div>
        </div>

        {/* Data Rows */}
        {detailedOddsData.map((game, index) => {
          if (!game || !game.odds || !game.odds[0]) return null;

          const homeMoneyline = getMoneylineForTeam(game.odds, game.home_team);
          const awayMoneyline = getMoneylineForTeam(game.odds, game.away_team);

          const matchingSpreads = getSpreadOrTotalGoalsForTeam(
            game.odds,
            game.home_team,
            game.away_team,
            game.sport,
          );

          const methodOfVictoryHome = getMethodOfVictory(game.odds, game.home_team);
          const methodOfVictoryAway = getMethodOfVictory(game.odds, game.away_team);

          const homeTotal = getTotalForTeam(game?.odds, 'under');
          const awayTotal = getTotalForTeam(game?.odds, 'over');

          const totalRoundsU = getTotalRounds(game?.odds, 'under');
          const totalRoundsO = getTotalRounds(game?.odds, 'over');


          const isMMA = game.sport === 'mma';
          const isGolf = game.sport === 'golf';
          const wrapperProps = 
            isMMA ? { to
            : `/sports/ufcMatchup/${game.id}` }
            : isGolf ? { to: `/sports/pgaOdds/${game.id}` }
            : { to: `/sports/propBets/${game.id}` };

          return (
            <Link key={index} {...wrapperProps} className="block bg-neutral-50 hover:bg-gray-200 m-2 rounded-lg shadow">
              <div className="grid grid-cols-10 items-center py-3">
                {/* Date/Time */}
                <div className="col-span-2 text-center">
                  <span className="flex justify-center items-center text-zinc-500 text-xs sm:text-sm font-normal">
                    {formatTimeOrDate(game.start_date)}
                  </span>
                </div>

                {/* Team Name with Logos */}
                <div className="col-span-2 flex flex-col items-start matchup-team-container">
                  <div className="flex items-center gap-2">
                    <img
                      className="w-6 h-6 object-cover"
                      src={game.away_team_details?.logo || 'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'}
                      alt="Away Team Logo"
                      loading="lazy"
                    />
                    <span className="text-indigo-950 text-xs sm:text-sm lg:text-base font-medium">
                      {game.away_team}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img
                      className="w-6 h-6 object-cover"
                      src={game.home_team_details?.logo || 'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'}
                      alt="Home Team Logo"
                      loading="lazy"
                    />
                    <span className="text-indigo-950 text-xs sm:text-sm lg:text-base font-medium">
                      {game.home_team}
                    </span>
                  </div>
                </div>

                {/* Moneyline */}
                <div className="col-span-1 lg:col-span-2 lg:ml-6 odds-column">
                  <div className="flex justify-center items-center space-x-3 mb-3">
                    <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                      {awayMoneyline?.price}
                    </span>
                  </div>
                  <div className="flex justify-center items-center space-x-3">
                    <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                      {homeMoneyline?.price}
                    </span>
                  </div>
                </div>

                {/* Spread */}
                {detailedOddsData[0].sport === 'mma' ? (
                  <div className="col-span-2 lg:ml-6 odds-column">
                    <div className="flex justify-around items-center space-x-3 mb-3">
                      <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                        {methodOfVictoryAway[0]?.name.split(' - ')[1]}
                      </span>
                      <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                        {methodOfVictoryAway[0]?.price}
                      </span>
                    </div>
                    <div className="flex justify-around items-center space-x-3">
                      <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                        {methodOfVictoryHome[0]?.name.split(' - ')[1]}
                      </span>
                      <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                        {methodOfVictoryHome[0]?.price}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="col-span-2 lg:ml-6 odds-column">
                    <div className="flex justify-around items-center space-x-3 mb-3">
                      <span className="spreadPoints text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                        {matchingSpreads?.awayOdds?.selection_points}
                      </span>
                      <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                        {matchingSpreads?.awayOdds?.price}
                      </span>
                    </div>
                    <div className="flex justify-around items-center space-x-3">
                      <span className="spreadPoints text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                        {matchingSpreads?.homeOdds?.selection_points}
                      </span>
                      <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                        {matchingSpreads?.homeOdds?.price}
                      </span>
                    </div>
                  </div>
                )}

                {/* Total */}
                {detailedOddsData[0].sport === 'mma' ? (
                  <div className="col-span-2 lg:ml-6 odds-column">
                    <div className="total-row mb-3 ">
                      <span className="text-green-600 total-label">O </span>
                      <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                        {totalRoundsO?.selection_points}
                      </span>
                      <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                        {totalRoundsO?.price}
                      </span>
                    </div>
                    <div className="total-row">
                      <span className="text-red-600 total-label">U </span>
                      <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                        {totalRoundsU?.selection_points}
                      </span>
                      <span className="text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                        {totalRoundsU?.price}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="col-span-2 lg:ml-6 odds-column">
                    <div className="total-row mb-3">
                      <span className="text-green-600 total-label">O</span>
                      <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                        {awayTotal?.selection_points}
                      </span>
                      <span className="total-price text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                        {awayTotal?.price}
                      </span>
                    </div>
                    <div className="total-row">
                      <span className="text-red-600 total-label">U</span>
                      <span className="text-zinc-500 text-xs sm:text-sm lg:text-base font-normal">
                        {homeTotal?.selection_points}
                      </span>
                      <span className="total-price text-zinc-400 text-xs sm:text-sm lg:text-base font-normal">
                        {homeTotal?.price}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Link>
          );
        })}
      </div>
    </>
  ) : (
    <div className="content h-full w-full flex items-center justify-center bg-white animate-bg">
      <Loading />
    </div>
  );
};
