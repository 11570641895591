import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { PropBetsView } from './PropBetsView';
import { usePropBets } from '../../hooks/usePropBets';
import { Loading } from '../../components/LoadingComponent';
import { GetPremiumModal } from '../../components/modals/GetPremiumModal';
import Tooltip from '../../components/Tooltip';

export const PropBetsPage = ( {darkMode} ) => {
  const { id: gameId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [teamSelection, setTeamSelection] = useState(true);
  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

  const {
    propBets,
    playersData,
    isSubscribed,
    modalVisible,
    showTooltip,
    loading,
    fetchPlayersData,
    handleUserAccessForData,
    setModalVisible,
    setShowTooltip,
  } = usePropBets(gameId, teamSelection, currentPage, userInfo);

  useEffect(() => {
    if (currentPage > 1) {
      fetchPlayersData(currentPage);
    }
  }, [currentPage, fetchPlayersData]);

  if (loading && !propBets) {
    return (
      <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
        <Loading />
      </div>
    );
  }

  return (
    <>
      {propBets ? (
        <PropBetsView
          data={propBets}
          playersData={playersData}
          liveGame={propBets?.gameScore}
          setTeamSelection={setTeamSelection}
          isSubscribed={isSubscribed}
          handleUserAccessForData={handleUserAccessForData}
          darkMode={darkMode}
        />
      ) : (
        <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
          <Loading />
        </div>
      )}

      <GetPremiumModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
      />

      {showTooltip && <Tooltip message="Join us to view other player's stats!" />}
    </>
  );
};
