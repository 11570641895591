import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_INTERESTS_FAIL,
  USER_UPDATE_INTERESTS_REQUEST,
  USER_UPDATE_INTERESTS_SUCCESS,
  USER_VERIFY_FAIL,
  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCCESS,
  GET_SUGGESTED_USERS_REQUEST,
  GET_SUGGESTED_USERS_SUCCESS,
  GET_SUGGESTED_USERS_FAIL,
  CHECK_FOR_TRIAL_USERS_REQUEST,
  CHECK_FOR_TRIAL_USERS_SUCCESS,
  CHECK_FOR_TRIAL_USERS_FAIL,
  SEND_SUPPORT_REQUEST,
  SEND_SUPPORT_SUCCESS,
  SEND_SUPPORT_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  SIGNUP_TRIAL_USER_REQUEST,
  SIGNUP_TRIAL_USER_SUCCESS,
  SIGNUP_TRIAL_USER_FAIL,
  CREATE_SUBSCRIPTION_SESSION_REQUEST,
  CREATE_SUBSCRIPTION_SESSION_SUCCESS,
  CREATE_SUBSCRIPTION_SESSION_FAIL,
  IS_ACCOUNT_PREMIUM_REQUEST,
  IS_ACCOUNT_PREMIUM_SUCCESS,
  IS_ACCOUNT_PREMIUM_FAIL,
  PAYMENT_SUCCESS_REQUEST,
  PAYMENT_SUCCESS_SUCCESS,
  PAYMENT_SUCCESS_FAIL,
  GET_SESSION_ID_REQUEST,
  GET_SESSION_ID_SUCCESS,
  GET_SESSION_ID_FAIL,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  INITIATE_PASSWORD_RESET_REQUEST,
  INITIATE_PASSWORD_RESET_SUCCESS,
  INITIATE_PASSWORD_RESET_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  FIND_USER_BY_IDENTIFIER_REQUEST,
  FIND_USER_BY_IDENTIFIER_SUCCESS,
  FIND_USER_BY_IDENTIFIER_FAIL,
} from '../constants/UserConstants.js';
import { userService } from '../../constants/services/users.js';

// LOGIN
export const login = (email, password, phone, navigate) => async dispatch => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });
    const response = await userService.login(email, password, phone)
    dispatch({ type: USER_LOGIN_SUCCESS, payload: response });

    localStorage.setItem('userInfo', JSON.stringify(response));
    dispatch(getUserDetails(response?.id));
    navigate(`/`);
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// CHECK FOR TRIAL USERS
export const checkForTrialUsers = (email, navigate) => async dispatch => {
  try {
    dispatch({ type: CHECK_FOR_TRIAL_USERS_REQUEST });

    const response = await userService.checkForTrialUsers(email)

    dispatch({ type: CHECK_FOR_TRIAL_USERS_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: CHECK_FOR_TRIAL_USERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// SIGNUP FOR TRIAL USERS
export const signupTrial = (email, name) => async dispatch => {
  try {
    dispatch({ type: SIGNUP_TRIAL_USER_REQUEST });

    const response = await userService.signupTrial(email, name)

    dispatch({ type: SIGNUP_TRIAL_USER_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: SIGNUP_TRIAL_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// USER DETAILS
export const getUserDetails = id => async dispatch => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });

    const response = await userService.fetchUserDetails(id)

    dispatch({ type: USER_DETAILS_SUCCESS, payload: response });
    return response;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

// LOGOUT
export const logout = navigate => async dispatch => {
  localStorage.removeItem('userInfo');
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  navigate('/');
};

// REGISTER
export const register =
  (name, email, password, phone, navigate) => async dispatch => {
    try {
      dispatch({ type: USER_REGISTER_REQUEST });

      const response = await userService.register(name, email, password, phone)

      dispatch({ type: USER_REGISTER_SUCCESS, payload: response });
      localStorage.setItem('userInfo', JSON.stringify(response));
      navigate('/verification');
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// VERIFY
export const verify = (email, phone, code, navigate) => async dispatch => {
  try {
    dispatch({ type: USER_VERIFY_REQUEST });

    const response = await userService.verify(email, phone, code)

    dispatch({ type: USER_VERIFY_SUCCESS });
    if (response && response?.isVerified) {
      localStorage.setItem('userInfo', JSON.stringify(response));
      dispatch(getUserDetails(response?.id));
      navigate(`/`);
    }
  } catch (error) {
    dispatch({
      type: USER_VERIFY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// GOOGLE REGISTER
export const loginWithGoogle = (token, navigate) => async dispatch => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const response = await userService.loginWithGoogle(token)

    dispatch({ type: USER_LOGIN_SUCCESS, payload: response });
    localStorage.setItem('userInfo', JSON.stringify(response));
    dispatch(getUserDetails(response.id));
    navigate(`/`);
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// FACEBOOK REGISTER
export const loginWithFacebook =
  (userID, accessToken, navigate) => async dispatch => {
    try {
      dispatch({ type: USER_LOGIN_REQUEST });

      const response = await userService.loginWithFacebook(userID, accessToken)

      dispatch({ type: USER_LOGIN_SUCCESS, payload: response });
      localStorage.setItem('userInfo', JSON.stringify(response));
      dispatch(getUserDetails(response.id));
      navigate(`/`);
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// UPDATE USER INTERESTS
export const updateUserInterests = (interests) => async dispatch => {
  try {
    dispatch({ type: USER_UPDATE_INTERESTS_REQUEST });

    const userInfo = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : {};

    const response = await userService.updateUserInterests(interests)

    dispatch({ type: USER_UPDATE_INTERESTS_SUCCESS, payload: response });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: response }); // Update the user details in the state

    // Update the local storage with the new user data
    const updatedUserInfo = {
      ...userInfo,
      ...response,
    };
    localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_INTERESTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSuggestedUsers = userId => async dispatch => {
  try {
    dispatch({ type: GET_SUGGESTED_USERS_REQUEST });

    const response = await userService.getSuggestedUsers(userId)

    dispatch({ type: GET_SUGGESTED_USERS_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: GET_SUGGESTED_USERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// UPDATE USER PREMIUM STATUS
export const createCheckoutSession = (plan, navigate) => async dispatch => {
  try {
    dispatch({ type: CREATE_SUBSCRIPTION_SESSION_REQUEST });

    const userInfo = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : {};

    if (!userInfo && !userInfo.token) navigate('/login')

    const userId = userInfo.id;
    const response = await userService.createCheckoutSession(plan, userId)
    dispatch({ type: CREATE_SUBSCRIPTION_SESSION_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: CREATE_SUBSCRIPTION_SESSION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// CHECK USER PREMIUM STATUS
export const checkAccountIsPremium = () => async dispatch => {
  try {
    dispatch({ type: IS_ACCOUNT_PREMIUM_REQUEST });

    const userInfo = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : {};

    const userId = userInfo.id;

    const response = await userService.checkAccountIsPremium(userId)
    dispatch({ type: IS_ACCOUNT_PREMIUM_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: IS_ACCOUNT_PREMIUM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Action to handle successful payment
export const cancelSubscription = () => async (dispatch) => {
  try {
    dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST });

    const response = await userService.cancelSubscription()

    dispatch({ type: CANCEL_SUBSCRIPTION_SUCCESS, payload: response });

    dispatch(checkAccountIsPremium())
    return response;
  } catch (error) {
    dispatch({
      type: CANCEL_SUBSCRIPTION_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// SUBMIT SUPPORT
export const sendSupportEmail =
  ({ userEmail, userName, userMessage }) =>
    async dispatch => {
      try {
        dispatch({ type: SEND_SUPPORT_REQUEST });

        await userService.sendSupportEmail(userEmail, userName, userMessage)

        dispatch({ type: SEND_SUPPORT_SUCCESS });
      } catch (error) {
        dispatch({
          type: SEND_SUPPORT_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

// UPDATE USER PROFILE
export const updateUserProfile =
  ({ userId, name, bio, headline, profileImg }) =>
    async dispatch => {
      try {
        dispatch({ type: USER_UPDATE_PROFILE_REQUEST });

        const userInfo = localStorage.getItem('userInfo')
          ? JSON.parse(localStorage.getItem('userInfo'))
          : {};

        const formData = new FormData();
        formData.append('name', name);
        formData.append('bio', bio);
        formData.append('headline', headline);
        if (profileImg instanceof File) {
          formData.append('profileImg', profileImg);
        }

        const response = await userService.updateUserProfile(formData)

        dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: response });
        // Update the local storage with the new user data
        const updatedUserInfo = {
          ...userInfo,
          name: response.data.name,
          bio: response.data.bio,
          headline: response.data.headline,
          profileImg: response.data.profileImg,
        };
        localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
        dispatch(getUserDetails(userId));
      } catch (error) {
        dispatch({
          type: USER_UPDATE_PROFILE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

// CHANGE PASSWORD
export const changePassword = (oldPassword, newPassword) => async dispatch => {
  try {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });

    const response = await userService.changePassword(oldPassword, newPassword)

    dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: response });
  } catch (error) {
    dispatch({
      type: CHANGE_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// FIND USER BY IDENTIFIER
export const findUserByIdentifier = (identifier) => async dispatch => {
  try {
    dispatch({ type: FIND_USER_BY_IDENTIFIER_REQUEST });

    const response = await userService.findUserByIdentifier(identifier)
    dispatch({ type: FIND_USER_BY_IDENTIFIER_SUCCESS, payload: response });
    return response
  } catch (error) {
    dispatch({
      type: FIND_USER_BY_IDENTIFIER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// INITIATE PASSWORD RESET
export const initiatePasswordReset = (identifier) => async dispatch => {
  try {
    dispatch({ type: INITIATE_PASSWORD_RESET_REQUEST });

    const response = await userService.initiatePasswordReset(identifier)

    dispatch({ type: INITIATE_PASSWORD_RESET_SUCCESS, payload: response });
  } catch (error) {
    dispatch({
      type: INITIATE_PASSWORD_RESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// RESET PASSWORD
export const resetPassword = (token, newPassword, navigate) => async dispatch => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    const response = await userService.resetPassword(token, newPassword)

    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response });
    await navigate('/login')
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
