import React from 'react';
import { Link } from 'react-router-dom';
import { getMoneylineForTeam, getSpreadOrTotalGoalsForTeam, getTotalForTeam, getTotalRounds, getMethodOfVictory } from '../utils/sportStats/oddsFunctions';

const MatchupCard = ({ matchup, selectedOddsType }) => {
  const getFormattedRecord = team => {
    const winLossAllGames = team?.records?.['Win/Loss']?.['Regular Season Games'];
    const wins = winLossAllGames?.wins ?? '';
    const losses = winLossAllGames?.losses ?? '';
    return `${wins} - ${losses}`;
  };

  const getCurrentPeriod = (matchup) => {
    if (matchup.status === 'Completed') {
      return 'Final';
    }

    if (matchup.status === 'Live') {
      if (['League of Legends', 'Valorant', 'CS2'].includes(matchup.league)) {
        return 'Live';
      }
      if (matchup?.clock && matchup?.period) {
        const sport = matchup.sport.toLowerCase();
        const getOrdinalSuffix = (number) => {
          if (number % 10 === 1 && number % 100 !== 11) {
            return 'st';
          } else if (number % 10 === 2 && number % 100 !== 12) {
            return 'nd';
          } else if (number % 10 === 3 && number % 100 !== 13) {
            return 'rd';
          } else {
            return 'th';
          }
      };

      const periodSuffix = (period) => {
        const suffix = getOrdinalSuffix(period);
        if (sport === 'wnba' || sport === 'nba' || sport === 'basketball') {
          return `${period}${suffix} quarter`;
        } else if (sport === 'mlb' || sport === 'baseball') {
          return `${period}${suffix} inning`;
        } else {
          return `${period}${suffix} period`;
        }
      };
      return `${matchup.clock} ${periodSuffix(matchup.period)}`;
    }
  }
    return '';
  };

  const awayScore = matchup?.gameResult?.[0]?.score_away_total ?? 'TBD';
  const homeScore = matchup?.gameResult?.[0]?.score_home_total ?? 'TBD';

  const awayRecord = getFormattedRecord(matchup.away_team_details);
  const homeRecord = getFormattedRecord(matchup.home_team_details);

  const currentPeriod = getCurrentPeriod(matchup);

  const isMMA = matchup.sport === 'mma';
  const WrapperComponent = isMMA ? 'div' : Link;
  const wrapperProps = isMMA ? {} : { to: `/sports/propBets/${matchup.game_id}` };

  // Retrieve odds based on selectedOddsType
  let awayOdds, homeOdds;
  switch (selectedOddsType) {
    case 'money_line':
      awayOdds = getMoneylineForTeam(matchup?.odds?.odds, matchup?.away_team_details?.team_name);
      homeOdds = getMoneylineForTeam(matchup?.odds?.odds, matchup?.home_team_details?.team_name);
      break;
    case 'point_spread':
      const matchingSpreads = matchup?.odds?.odds ? getSpreadOrTotalGoalsForTeam(
        matchup?.odds?.odds,
        matchup?.home_team_details?.team_name,
        matchup?.away_team_details?.team_name,
        matchup?.sport
      ) : {};
      awayOdds = matchingSpreads?.awayOdds;
      homeOdds = matchingSpreads?.homeOdds;
      break;
    case 'total_points':
      awayOdds = getTotalForTeam(matchup?.odds?.odds, 'over');
      homeOdds = getTotalForTeam(matchup?.odds?.odds, 'under');
      break;
    case 'method_of_victory':
      awayOdds = getMethodOfVictory(matchup?.odds?.odds, matchup?.away_team_details?.team_name)?.[0];
      homeOdds = getMethodOfVictory(matchup?.odds?.odds, matchup?.home_team_details?.team_name)?.[0];
      break;
    case 'total_rounds':
      awayOdds = getTotalRounds(matchup?.odds?.odds, 'over');
      homeOdds = getTotalRounds(matchup?.odds?.odds, 'under');
      break;
    default:
      break;
  }

  return (
    <WrapperComponent
      {...wrapperProps}
      className="flex h-24 items-center justify-between whitespace-nowrap ml-3"
    >
      <div className="flex items-center w-full">
        <div className="flex flex-col items-start flex-grow">
          {(matchup.status === 'Live' || matchup.status === 'Completed') && (
            <span className={`text-sm font-bold leading-tight mb-2 ${matchup.status === 'Completed' ? 'text-gray-600' : 'text-red'}`}>
              {currentPeriod}
            </span>
          )}
          <div className="flex items-center justify-between w-60">
            {/* gap between top team and bottom team */}
            <div className="flex flex-col items-start gap-2">
              <div className="flex items-center gap-2 w-full">
                <img
                  className="w-6 h-6 object-cover"
                  src={matchup.away_team_details?.logo}
                  alt="Away Team Logo"
                  loading="lazy"
                />
                <div className="flex flex-grow justify-between items-center">
                  <span className="text-base font-semibold leading-tight flex-grow">
                    {isMMA
                      ? matchup.away_team_abb
                      : (matchup.away_team_details?.team_abbreviation || matchup.away_team_details?.team_name)}
                    <span className="text-sm text-gray-500 ml-1">
                      ({awayRecord})
                    </span>
                  </span>
                </div>
              </div>
              
              {/* gap between img and team name */}
              <div className="flex items-center gap-2 w-full">
                <img
                  className="w-6 h-6 object-cover"
                  src={matchup.home_team_details?.logo}
                  alt="Home Team Logo"
                  loading="lazy"
                />
                <div className="flex flex-grow justify-between items-center">
                  <span className="text-base font-semibold leading-tight flex-grow">
                    {isMMA
                      ? matchup.home_team_abb
                      : (matchup.home_team_details?.team_abbreviation || matchup.home_team_details?.team_name)}
                    <span className="text-sm text-gray-500 ml-1">
                      ({homeRecord})
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end gap-3">
              {(matchup.status === 'Completed' || matchup.status === 'Live') && (
                <>
                  <span className="text-sm font-bold leading-tight">
                    {awayScore}
                  </span>
                  <span className="text-sm font-bold leading-tight">
                    {homeScore}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        {matchup.status !== 'Completed' && matchup.status !== 'Live' && (
          <div className="flex flex-col items-end ml-6">
            <span className="text-neutral-500 text-sm font-medium leading-none mb-1">
              {(() => {
                const startDate = new Date(matchup.start_date);
                const isToday = startDate.toDateString() === new Date().toDateString();
                const formatter = new Intl.DateTimeFormat('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                });

                const formattedDate = formatter.format(startDate);
                return isToday ? formattedDate.replace('AM', 'AM').replace('PM', 'PM') : startDate.toLocaleDateString();
              })()}
            </span>
            <div className="text-neutral-500 text-sm font-normal leading-tight">
              {selectedOddsType === 'money_line' && awayOdds && homeOdds && (
                <>
                  <div style={{ textAlign: 'right', width: '100%' }}>{awayOdds.price}</div>
                  <div style={{ textAlign: 'right', width: '100%' }}>{homeOdds.price}</div>
                </>
              )}
              {selectedOddsType === 'point_spread' && awayOdds && homeOdds && (
                <>
                  <div style={{ textAlign: 'right', width: '100%' }}>{awayOdds.selection_points} ({awayOdds.price})</div>
                  <div style={{ textAlign: 'right', width: '100%' }}>{homeOdds.selection_points} ({homeOdds.price})</div>
                </>
              )}
              {selectedOddsType === 'total_points' && awayOdds && homeOdds && (
                <>
                  <div style={{ textAlign: 'right', width: '100%' }}>O {awayOdds.selection_points} ({awayOdds.price})</div>
                  <div style={{ textAlign: 'right', width: '100%' }}>U {homeOdds.selection_points} ({homeOdds.price})</div>
                </>
              )}
              {selectedOddsType === 'method_of_victory' && awayOdds && homeOdds && (
                <>
                  <div style={{ textAlign: 'right', width: '100%' }}>Method of Victory (Away): {awayOdds.name.split(' - ')[1]} ({awayOdds.price})</div>
                  <div style={{ textAlign: 'right', width: '100%' }}>Method of Victory (Home): {homeOdds.name.split(' - ')[1]} ({homeOdds.price})</div>
                </>
              )}
              {selectedOddsType === 'total_rounds' && awayOdds && homeOdds && (
                <>
                  <div style={{ textAlign: 'right', width: '100%' }}>O {awayOdds.selection_points} ({awayOdds.price})</div>
                  <div style={{ textAlign: 'right', width: '100%' }}>U {homeOdds.selection_points} ({homeOdds.price})</div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="w-px h-11 bg-zinc-300 rounded-2xl ml-2"></div>
    </WrapperComponent>
  );
};

export default MatchupCard;
